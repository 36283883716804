.ticket {
    &__print-btn-row{
        display: flex;
        justify-content: flex-end;
    }
    &__layout{
        border: 1px solid black;
        padding: 50px;
        &__header{
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;
            &__logo{

            }
            &__date{
                
            }
        }
        &__title{
            display: flex;
            justify-content: center;
            margin-bottom: 50px;
        }
        &__contact-data{
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;
        }
        &__client-data{
            display: flex;
            flex-direction: column;
            justify-content: initial;
            margin-bottom: 50px;
        }
        &__purchase{
            &-table{
                table{
                    width: 100%;
                    border: 1px solid black;
                    th{
                        padding: 25px;
                        text-align: center;
                        width: fit-content;
                        min-width: 25%;
                    }
                    td {
                        background-color: rgb(207, 207, 207);
                        padding: 25px;
                        text-align: center;
                        min-width: 25%;
                    }
                }
            }
        }
        &__resume-table{
            display: flex;
            justify-content: flex-end;
            margin-right: 100px;
            margin-top: 20px;
            p{
                margin-left: 150px;
            }
        }
    }
}