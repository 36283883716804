@import './../variables.scss';

.app__input {
  width: 100%;
  font-family: $font-family;

  a {
    margin-left: 20px;
    font-size: $font-small;
    color: $text-black;

    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }

  label {
    font-size: $font-normal;
    color: $text-black;
    margin: 1rem 0 0 0;
  }

  input[type=text],
  input[type=email],
  input[type=number],
  input[type=password],
  input[type=tel],
  textarea,
  select {
    font-family: $font-family;
    background: none;
    color: $text-black;
    font-size: $font-normal;
    font-weight: $light-weight;
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0;
    display: inline-block;
    box-sizing: border-box;

    border-radius: 5px;
    border: solid 1px #f0f0f0;

    @include desktop {
      padding: 10px 5px;
    }
  }

  input[type=text]:disabled {
    background: $white;
    color: $gray;
  }

  input::placeholder, textarea::placeholder {
    font-family: $font-family;
    color: $text-accent;
    //text-transform: capitalize;
    opacity: .7;
  }

  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type=submit]:hover {
    background-color: #45a049;
  }

  /* Customize the label (the container) */
  &__checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: $font-very-small;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type=checkbox] {
      position: absolute;
      left: 0;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      z-index: 1;

      &:checked~.app__input__checkbox__checkmark:after {
        display: block;
      }
    }

    /* Create a custom checkbox */
    &__checkmark {
      position: absolute;
      top: 0;
      left: 5px;
      height: 20px;
      width: 20px;
      border: solid 2px #443197;
      background-color: $white;

      &::after {
        content: "";
        position: absolute;
        display: none;

        left: 5px;
        top: 1px;
        width: 6px;
        height: 13px;
        border: solid $black;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:hover {
        background-color: #ccc;
      }
    }
  }

  &-center {
    input[type=text],
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=tel],
    select {
      text-align: center;
    }

  }

  &__error-message {
    text-align: left;
    font-size: $font-very-small;
    color: $color-highlight;
  }
}