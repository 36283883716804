@import 'src/shared/variables.scss';

.line {
  margin: 0 25px;
  height: 100%;

  &__white {
    border-left: 1px solid $white;
  }

  &-horizontal {
    border-color: $white;
  }
}