.we_hiit {
  .we-train-cls-1,
  .we-train-cls-2,
  .we-train-cls-3 {
    stroke: #292a6d;
    stroke-miterlimit: 10;
    stroke-width: 3px;
  }

  .text {
    font-family: ArialMT, Arial;
    font-size: 1rem;
    stroke: #fff;
  }

  .we-train-cls-1,
  .we-train-cls-5 {
    fill: #8a85b2;
  }

  .we-train-cls-6,
  .we-train-cls-4 {
    font-family: ArialMT, Arial;
    font-size: 24.1px;
  }

  .we-train-cls-6,
  .we-train-cls-4,
  .we-train-cls-7 {
    isolation: isolate;
  }

  .we-train-cls-3 {
    fill: none;
  }

  .we-train-cls-5 {
    stroke-width: 0px;
  }

  .stroke {
    stroke: url(#SVGID_19_);
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
}
