@import 'src/shared/variables.scss';



.side-item {
    height: 70px;
    margin-left: 0;
    padding-left: 20px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    transition: all .2s ease-in-out;
    
    .dot {
      height: 20px;
      width: 20px;
      background-color: #944d9e;
      border-radius: 50%;
      display: inline-block;
      margin-left: auto;
      margin-right: 10px;
    }

    hr {
      border-top: 1px solid $gray;
      margin: 0px;
      padding: 0px;
    }
  
    svg {
      margin-right: 10%;
    }
  
    p {
      font-size: 16px;
    }
  }
  
  .side-item:hover {
    background-color: $primary-color;
  }
  
  @media screen and (min-width: 1024px) and (max-width: 2048px) {
    .side-item p {
      font-size: 100%;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1023px){
    .side-item p {
      font-size: 90%;
    }
  }
  
  @media screen and (min-width: 480px) and (max-width: 767px){
    .side-item p {
      font-size: 80%;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 479px){
    .side-item p {
      font-size: 70%;
    }
  }