@import 'src/shared/variables';

.edit-modal {
    &__avatar-row{
        display: flex;
        justify-content: center;
        img{
            width: 180px;  
            height: 180px;
            border: 1px solid rgba(182, 182, 182, 0.63) ;
        }
    }
    &__button-row {
        display: flex;
        justify-content: center;
    }
    &__input{
            width: 100%;
        }
        
        &__label {
            color: $black;
        }
        
        &__button {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            background-color: $primary-color;
            border-radius: 5px;
        }
    }                        