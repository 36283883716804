@import 'src/shared/variables.scss';

.days-header {
  display: flex;
  flex-wrap: nowrap;
  background: $black;
  padding: 1rem 0;
  margin: 0;
  width: 100%;
  .days-column {

    &__title {
      display: flex;
      justify-content: center;
      flex-wrap: nowrap;
      font-weight: $light-weight;
      font-size: 16px;
      text-align: center;
      color: $white;
      margin: 0;
  
      span {
        padding-left: 5px;
        color: #dadada;
      }

      @include desktop {
        font-size: $font-very-small;

        span {
          padding-left: 0;
        }
      }  
    }
    
  }
}