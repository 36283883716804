.we_ride {
  .st0 {
    fill: none;
    stroke: url(#SVGID_1_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: none;
    stroke: url(#SVGID_2_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st2 {
    fill: none;
    stroke: url(#SVGID_3_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st3 {
    fill: none;
    stroke: url(#SVGID_4_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st4 {
    fill: none;
    stroke: url(#SVGID_5_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill: none;
    stroke: url(#SVGID_6_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st6 {
    fill: none;
    stroke: url(#SVGID_7_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st7 {
    fill: none;
    stroke: url(#SVGID_8_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st8 {
    font-family: 'ArialMT';
  }

  .st9 {
    font-size: 12px;
  }

  .st10 {
    fill: none;
    stroke: url(#SVGID_9_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st11 {
    fill: none;
    stroke: url(#SVGID_10_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st12 {
    fill: none;
    stroke: url(#SVGID_11_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st13 {
    fill: none;
    stroke: url(#SVGID_12_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st14 {
    fill: none;
    stroke: url(#SVGID_13_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st15 {
    fill: none;
    stroke: url(#SVGID_14_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st16 {
    fill: none;
    stroke: url(#SVGID_15_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st17 {
    fill: none;
    stroke: url(#SVGID_16_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st18 {
    fill: none;
    stroke: url(#SVGID_17_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st19 {
    fill: none;
    stroke: url(#SVGID_18_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st20 {
    fill: none;
    stroke: url(#SVGID_19_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st21 {
    fill: none;
    stroke: url(#SVGID_20_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st22 {
    fill: none;
    stroke: url(#SVGID_21_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st23 {
    fill: none;
    stroke: url(#SVGID_22_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st24 {
    fill: none;
    stroke: url(#SVGID_23_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st25 {
    fill: none;
    stroke: url(#SVGID_24_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st26 {
    fill: none;
    stroke: url(#SVGID_25_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st27 {
    fill: none;
    stroke: url(#SVGID_26_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st28 {
    fill: none;
    stroke: url(#SVGID_27_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st29 {
    fill: none;
    stroke: url(#SVGID_28_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st30 {
    fill: none;
    stroke: url(#SVGID_29_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st31 {
    fill: none;
    stroke: url(#SVGID_30_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st32 {
    fill: none;
    stroke: url(#SVGID_31_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st33 {
    fill: none;
    stroke: url(#SVGID_32_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st34 {
    fill: none;
    stroke: url(#SVGID_33_);
    stroke-width: 2;
    stroke-miterlimit: 10;
  }
}