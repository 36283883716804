@import 'src/shared/variables';

.dark-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(223deg, #405796, #a01080);
    background-size: 400% 400%;
    opacity: .8;
    
    -webkit-animation: weeanimation 30s ease infinite;
    -moz-animation: weeanimation 30s ease infinite;
    -o-animation: weeanimation 30s ease infinite;
    animation: weeanimation 30s ease infinite;
}

.image-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

@-webkit-keyframes weeanimation {
    0%{background-position:0% 97%}
    50%{background-position:100% 4%}
    100%{background-position:0% 97%}
}
@-moz-keyframes weeanimation {
    0%{background-position:0% 97%}
    50%{background-position:100% 4%}
    100%{background-position:0% 97%}
}
@-o-keyframes weeanimation {
    0%{background-position:0% 97%}
    50%{background-position:100% 4%}
    100%{background-position:0% 97%}
}
@keyframes weeanimation {
    0%{background-position:0% 97%}
    50%{background-position:100% 4%}
    100%{background-position:0% 97%}
}
