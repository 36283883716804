@import '../../shared/variables.scss';

.instructors {
    &__search{
        &-grid {
            width: 100%;
            height: 100px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            align-items: center;
            justify-items: stretch;
            grid-column-gap: 50px;
            margin-bottom: 20px;
            &__new-button {
                grid-column-start: 3;
                grid-column-end: span 1;
                grid-row-start: 2;
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-wrap: wrap;
    }
    &__header-title {
        margin-bottom: 5vh;
    }
    &__header-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    &__button-row{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
    }
    &__table {
        &__th{
                width: fit-content;
        }
        &__buttons {
            height: 100%;
            display: flex;
            justify-content: space-evenly ;
                button {
                    border: none;
                    background-color: transparent;
                }
        }
    }
}